import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useConfig from 'hooks/useConfig';
import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({ handleDrawerToggle }: any) => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  const { version, year, copyright, lastUpdate } = useConfig();
  const { user } = useAuth();
  console.log(version);

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        if (item.id === 'building' && user?.buildingIds?.length === 0 && !user.super) return false;
        else return <NavGroup handleDrawerToggle={handleDrawerToggle} key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        pt: 0,
        '& > ul:first-of-type': { mt: 0 },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box>{navGroups}</Box>
      {drawerOpen && (
        <Box p={2} sx={{ whiteSpace: 'normal', width: '100%' }}>
          <Typography component="p" variant="caption" color="textSecondary">
            Versione:{' '}
            <Typography color="primary" component="span" variant="caption">
              {version}
            </Typography>
            <br />
            Aggiornamento:{' '}
            <Typography color="primary" component="span" variant="caption">
              {dayjs(lastUpdate).format('D MMMM YYYY | HH:mm')}
            </Typography>
            <br />© {year} {copyright}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Navigation;

// ==============================|| PERMISSIONS GUARD ||============================== //

import useAuth from 'hooks/useAuth';
import { usePermissions } from 'hooks/usePermissions';
import Unauthorized from 'pages/maintenance/unauthorized';
import { useLocation } from 'react-router';

type Props = {
  children: JSX.Element | JSX.Element[];
  testMode?: boolean;
};
const PermissionsGuard = ({ children, testMode }: Props): JSX.Element => {
  const { user } = useAuth();
  const location = useLocation();
  const { checkPermission } = usePermissions();

  return user.super || testMode || checkPermission(location.pathname, 'read', { type: 'route' }) ? <>{children}</> : <Unauthorized />;
};

export default PermissionsGuard;

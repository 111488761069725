import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import DocRoutes from './DocRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const isDev = process.env.NODE_ENV === 'development';
export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, ...(isDev ? [DocRoutes] : [])]);
}

import { Link } from 'react-router-dom';

// material-ui
import { Box, ButtonBase, SxProps, Typography } from '@mui/material';

// project import
// import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import useConfig from 'hooks/useConfig';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: string;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { defaultPath, appName } = useConfig();
  return (
    <ButtonBase disableRipple component={Link} to={!to ? defaultPath : to} sx={sx}>
      {isIcon ? (
        <LogoIcon />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
          <LogoIcon />
          <Typography variant="h3">{appName}</Typography>
        </Box>
      )}
    </ButtonBase>
  );
};

export default LogoSection;

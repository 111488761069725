import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - login
const Storybook = Loadable(lazy(() => import('pages/maintenance/storybook')));

// ==============================|| AUTH ROUTING ||============================== //

const DocRoutes = {
  path: '/storybook',
  element: <Storybook />
};

export default DocRoutes;

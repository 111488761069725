import React, { createContext, ReactElement, useEffect, useReducer, useState } from 'react';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import config from 'config';
import { AuthProps, UserProfile } from 'types/auth';
import axios from 'axios';
import { queryClient } from 'App';
// import useConfig from 'hooks/useConfig';

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const axiosAuth = axios.create({
  baseURL: config.serverUrl + 'server/' + config.APIVersion + '/auth'
});

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext<any>(null);

type Props = {
  children: ReactElement;
  testMode?: boolean;
};
export const AuthProvider = ({ children, testMode }: Props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const [user, setUser] = useState<UserProfile | null>(
    testMode
      ? {
          id: '0',
          super: true,
          firstname: 'Marco',
          lastname: 'Martone',
          permissions: []
        }
      : null
  );

  // const { serverUrl, APIVersion } = useConfig()

  useEffect(
    () => {
      if (user) {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: {
              id: user.id,
              email: user.email!,
              name: `${user.firstname} ${user.lastname}`,
              firstname: user.firstname,
              lastname: user.lastname,
              role: user.role,
              super: user.role === 'super',
              permissions: {
                authResources: user.authResources
              },
              lastEdit: user.lastEdit
            }
          }
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, user]
  );

  const authEmailPasswordSignIn = (param1: string, param2: string) =>
    axiosAuth({
      method: 'post',
      url: '/welcome',
      params: { param1, param2 }
    }).then((res) => {
      setUser(res.data.user);
      localStorage['accessToken'] = res.data.accessToken;
      localStorage['refreshToken'] = res.data.refreshToken;
    });

  const authTokenLogin = () =>
    axiosAuth({
      method: 'get',
      url: '/me',
      headers: {
        Authorization: `Bearer ${localStorage['accessToken']}`
      }
    })
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        if (error.response?.data?.message === 'invalid_token') {
          refreshToken();
        } else {
          logout();
        }
      });

  const refreshToken = () =>
    axiosAuth({
      method: 'post',
      url: '/refresh',
      headers: { Authorization: `Bearer ${localStorage['refreshToken']}` }
    })
      .then(({ data }) => {
        localStorage['accessToken'] = data.accessToken;
        localStorage['refreshToken'] = data.refreshToken;
        setUser(data.user);
      })
      .catch(() => {
        logout();
      });

  const logout = () => {
    localStorage['accessToken'] = '';
    localStorage['refreshToken'] = '';
    queryClient.clear();
    dispatch({
      type: LOGOUT
    });
  };

  const resetPassword = (newPassword: string, oldPassword?: string) =>
    axiosAuth({
      method: 'patch',
      url: '/password',
      headers: { Authorization: `Bearer ${localStorage['accessToken']}` },
      data: {
        oldPassword: oldPassword ?? undefined,
        newPassword
      }
    });

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        authEmailPasswordSignIn,
        login: () => {},
        logout,
        resetPassword,
        updateProfile,
        authTokenLogin,
        refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import { WebsocketContext } from 'contexts/WebsocketContext';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refetchErrors, refetchMaintenance, refetchNotify } from 'store/reducers/helpers';
//import { openSnackbar } from '../../store/reducers/snackbar';

const Websocket = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const socket = useContext(WebsocketContext);
  const dispatch = useDispatch();

  socket.connect();

  socket.on('connect', function () {
    console.log('Connected');
  });

  socket.on('connect_error', (err) => {
    console.log('Connection failed');
    // @ts-ignore
    console.log(err);
    if (err.message === 'invalid_token') {
      // @ts-ignore
      socket.auth.token = localStorage['accessToken'];
      socket.connect();
    }
  });

  socket.on('disconnect', () => {
    console.log('Disconnected');
  });

  socket.on('test', (data) => {
    console.log(data);
  });
  socket.on('errorsUpdate', (data) => {
    if (data.mode === 'refresh') {
      dispatch(refetchErrors(true));
    }
  });

  socket.on('notifications', (data) => {
    if (data.mode === 'refresh') {
      dispatch(refetchNotify(true));
    }
  });
  socket.on('appState', (data) => {
    if (data.mode === 'refresh') {
      dispatch(refetchMaintenance(true));
    }
  });

  useEffect(() => {
    if ((localStorage['accessToken'] && localStorage['accessToken'] !== 'undefined') || localStorage['accessToken'] !== 'null') {
      // @ts-ignore
      socket.auth.token = localStorage['accessToken'];
      socket.connect();
    }
  }, [localStorage['accessToken']]);
  return <>{children}</>;
};

export default Websocket;

import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { socket, WebsocketProvider } from 'contexts/WebsocketContext';
import Websocket from 'components/custom/Websocket';
import Maintenance from 'components/custom/Maintenance';
import PermissionsGuard from 'utils/route-guard/PermissionsGuard';
import { AskConfirmationProvider } from 'components/custom/AskConfirmation';
import AllErrors from 'pages/app-errors/all-errors';
import { RouteObject } from 'react-router';
import GtvErrors from '../pages/app-errors/gtv-errors';
// ADMIN ROUTES

// users
const AllUsers = Loadable(lazy(() => import('pages/users/all-users')));

// create user
const UserProfile = Loadable(lazy(() => import('pages/users/new-user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/users/new-user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/users/new-user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/users/new-user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/users/new-user/TabSettings')));

// user account
const AccountProfile = Loadable(lazy(() => import('pages/users/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/users/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/users/account/TabEdit')));
const AccountTabAccount = Loadable(lazy(() => import('sections/users/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/users/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/users/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/users/account/TabSettings')));
const AccountTabNotifications = Loadable(lazy(() => import('sections/users/account/TabNotifications')));
// user account
const Settings = Loadable(lazy(() => import('pages/settings/settings')));
const SettingsTabRole = Loadable(lazy(() => import('sections/settings/TabRole')));
const SettingsTabSettings = Loadable(lazy(() => import('sections/settings/TabSettings')));

// app-errors
// const AllErrors = Loadable(lazy(() => import('pages/app-errors/all-errors2')));
const Log = Loadable(lazy(() => import('pages/app-errors/log')));

// notifications
const AllNotifications = Loadable(lazy(() => import('pages/all-notifications/all-notifications')));
const Notification = Loadable(lazy(() => import('pages/all-notifications/notification')));

// tasks
const TasksMenu = Loadable(lazy(() => import('pages/task-manager/task-manager-menu')));
const Task = Loadable(lazy(() => import('pages/task-manager/task')));
// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const Unauthorized = Loadable(lazy(() => import('pages/maintenance/unauthorized')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <WebsocketProvider value={socket}>
            <Websocket>
              <PermissionsGuard>
                <AskConfirmationProvider>
                  <>
                    <MainLayout />
                    <Maintenance />
                    {/* <AskConfirmation /> */}
                  </>
                </AskConfirmationProvider>
              </PermissionsGuard>
            </Websocket>
          </WebsocketProvider>
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'app-errors',
          element: <AllErrors />,
          children: [
            {
              path: ':appId/:logId',
              element: <Log />
            }
          ]
        },
        {
          path: 'gtv-errors',
          element: <GtvErrors />,
          children: [
            {
              path: ':appId/:logId',
              element: <Log />
            }
          ]
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              element: <AllUsers />
            },
            {
              path: 'new',
              element: <UserProfile />,
              children: [
                {
                  path: '',
                  element: <UserTabPersonal />
                },
                {
                  path: 'payment',
                  element: <UserTabPayment />
                },
                {
                  path: 'password',
                  element: <UserTabPassword />
                },
                {
                  path: 'settings',
                  element: <UserTabSettings />
                }
              ]
            },
            {
              path: ':id',
              element: <AccountProfile />,
              children: [
                {
                  path: '',
                  element: <AccountTabProfile />
                },
                {
                  path: 'edit',
                  element: <AccountTabPersonal />
                },
                {
                  path: 'my-account',
                  element: <AccountTabAccount />
                },
                {
                  path: 'notifications',
                  element: <AccountTabNotifications />
                },
                {
                  path: 'password',
                  element: <AccountTabPassword />
                },
                {
                  path: 'role',
                  element: <AccountTabRole />
                },
                {
                  path: 'settings',
                  element: <AccountTabSettings />
                }
              ]
            }
          ]
        },
        {
          path: 'me',
          element: <AccountProfile />,
          children: [
            {
              path: '',
              element: <AccountTabProfile />
            },
            {
              path: 'edit',
              element: <AccountTabPersonal />
            },
            {
              path: 'my-account',
              element: <AccountTabAccount />
            },
            {
              path: 'password',
              element: <AccountTabPassword />
            },
            {
              path: 'notifications',
              element: <AccountTabNotifications />
            },
            {
              path: 'role',
              element: <AccountTabRole />
            },
            {
              path: 'settings',
              element: <AccountTabSettings />
            }
          ]
        },
        {
          path: 'privacy',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'settings',
          element: <Settings />,
          children: [
            {
              path: '',
              element: <SettingsTabSettings />
            },
            {
              path: 'auth-resources',
              element: <SettingsTabRole />
            }
          ]
        },
        {
          path: 'tasks',
          element: <TasksMenu />,
          children: [
            {
              path: ':projectId',
              element: <Task />
            }
          ]
        },
        {
          path: 'notifications',
          element: <AllNotifications />,
          children: [
            {
              path: ':notifyId',
              element: <Notification />
            }
          ]
        }
      ]
    },
    {
      path: '/unauthorized',
      element: <Unauthorized />
    },
    {
      path: '/maintenance',
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        }
      ]
    }
  ]
};

export default MainRoutes;

import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import useConfig from 'hooks/useConfig';

// types
import { RootStateProps } from 'types/root';
import { openDrawer } from 'store/reducers/menu';
import { formatText } from 'utils/formatText';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { useError } from 'hooks/useError';
import ShowLoader from 'components/custom/ShowLoader';

// ==============================|| MAIN LAYOUT ||============================== //

const FallbackComponent = (fallbackProps: FallbackProps) => {
  const { handleError } = useError();
  const { resetErrorBoundary, error } = fallbackProps;
  let message: string = "C'è stato un errore nel caricamento della risorsa, si è pregati di ricaricare la pagina.";

  const handleBackHome = () => {
    window.location.reload();
  };

  handleError(error);

  return (
    <Box p={3}>
      {/* {error.message} */}
      <Typography variant="h4">{message}</Typography>
      <Box display={'flex'}>
        <Button onClick={() => resetErrorBoundary()}>Riprova</Button>
        <Button onClick={handleBackHome}>Ricarica</Button>
      </Box>
    </Box>
  );
};

const OutletWithError = withErrorBoundary(Outlet, {
  FallbackComponent,
  onError(error: Error, info) {
    console.log('error');

    // sendError({
    //   componentStack: info.componentStack ?? undefined,
    //   stack: error.stack as string,
    //   message: (error as any).response?.data?.message ?? error.message,
    //   status: (error as any).response?.data?.statusCode ?? undefined,
    // });
  }
});

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const { logId, appId } = useParams();

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const location = useLocation();
  const params = useParams();
  const [title, setTitle] = useState('');

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  // useEffect(() => {
  //   if (open !== drawerOpen) setOpen(drawerOpen);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [drawerOpen]);

  useEffect(() => {
    if (location.pathname.includes('/me/')) {
      setTitle('Profilo');
    } else if (location.pathname) {
      if (location.pathname.includes('/users/') && params.id) {
        setTitle('Modifica Utente');
      } else if (location.pathname === `/app-errors/${logId}`) {
        setTitle(`LOG ID - ${logId}`);
      } else if (location.pathname.includes('/tasks')) {
        setTitle('Task manager');
      } else if (location.pathname === `/tasks/${appId}`) {
        setTitle('Tasks');
      }
    } else {
      setTitle(formatText(location.pathname));
    }
  }, [location, params]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <ShowLoader />
      <Header title={title} open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1 }}>
        <Toolbar />
        {container && (
          <>
            <OutletWithError />
          </>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <OutletWithError />
            <Footer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BellOutlined,
  ChromeOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  FundViewOutlined
} from '@ant-design/icons';
import ReportIcon from '@mui/icons-material/Report';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  PersonIcon,
  GroupIcon,
  ExclamationCircleOutlined,
  BellOutlined,
  SettingOutlined,
  ReportIcon,
  AssignmentTurnedInIcon,
  FundViewOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const administration: NavItemType = {
  id: 'administration',
  title: <FormattedMessage id="administration" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
      resource: 'dashboard'
    },
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      icon: icons.GroupIcon,
      breadcrumbs: false,
      url: '/users',
      resource: 'users'
    },
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      icon: icons.PersonIcon,
      breadcrumbs: false,
      url: '/me',
      resource: 'dashboard'
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined,
      breadcrumbs: false,
      resource: 'settings',
      permission: 'update'
    },
    {
      id: 'app-errors',
      title: <FormattedMessage id="app-errors" />,
      type: 'item',
      url: '/app-errors',
      icon: icons.ReportIcon,
      breadcrumbs: false,
      resource: 'apps/errors',
      permission: 'read'
    },
    {
      id: 'gtv-errors',
      title: <FormattedMessage id="gtv-errors" />,
      type: 'item',
      url: '/gtv-errors',
      icon: icons.FundViewOutlined,
      breadcrumbs: false,
      resource: 'apps/errors',
      permission: 'read'
    },
    {
      id: 'tasks',
      title: <FormattedMessage id="task-manager" />,
      type: 'item',
      url: '/tasks',
      icon: icons.AssignmentTurnedInIcon,
      breadcrumbs: false
    }
  ]
};

export default administration;
